import React, { useRef } from 'react'

import { functions } from '@wap-client/core'

import Icon from '../icon'
import { Column, Container, Row } from '../gridview'
import Anchor from '../anchor/Anchor'

import { useInView, motion, useScroll, useTransform } from 'framer-motion'

import { SectionProps } from './types'
import { duration } from 'moment'

const buttonVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  hidden: {
    opacity: 0,
    y: -50,
  },
}

const titleVariant = {
  visible: { opacity: 1, y: 0 },
  hidden: {
    opacity: 0,
    y: -50,
  },
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
  const { anchor, children, container, description, title, ...rest } = props

  const ref = useRef(null)
  const isInView = useInView(ref)

  let titleWords: string[] = []
  if (title) {
    titleWords = title?.replace(/<[^>]*>?/gm, '').split(' ')
  }

  return (
    <section
      ref={ref}
      {...rest}
      className={functions.classnames('section', rest.className)}
    >
      <header className="section-header">
        <Container {...container}>
          <Row>
            <Column>
              <motion.div
                variants={buttonVariant}
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
              >
                {anchor && (
                  <Anchor {...anchor}>
                    {anchor.children}
                    <Icon size={'small'} name="arrow-right" />
                  </Anchor>
                )}
              </motion.div>
            </Column>
          </Row>
          <Row>
            <Column>
              <h2 className="section-header-title">
                {titleWords.map((el: string, i: number) => (
                  <motion.span
                    variants={titleVariant}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    transition={{
                      duration: 0.5,
                      delay: i / 10,
                    }}
                    key={i}
                  >
                    {el}{' '}
                  </motion.span>
                ))}
              </h2>
            </Column>
          </Row>
          <Row>
            {description && (
              <p
                className="section-header-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Row>
        </Container>
      </header>
      {children}
    </section>
  )
}

export default Section
